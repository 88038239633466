import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const MiddleHomeComponent = () => {
    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={4} alignItems="center">
                {/* Coluna da imagem */}
                <Grid item xs={12} md={6}>
                    <img
                        src="/images/eng_software.png"
                        alt="Placeholder"
                        style={{ width: '100%', height: 'auto'}}
                    />
                </Grid>

                {/* Coluna do texto */}
                <Grid item xs={12} md={6}>
                    <Typography variant="h6" sx={{ 
                        textAlign: 'justify',    
                        textIndent: '30px',
                        lineHeight: 1.6 
                        }}>
                    Porque boas ideias, sozinhas, não garantem bons resultados. Elas precisam ser acompanhadas, minuciosamente planejadas e, acima de tudo, bem executadas para terem o melhor resultado possível. E é pra isso que estamos aqui, pra caminharmos e alcançarmos nossos objetivos juntos!
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MiddleHomeComponent;
