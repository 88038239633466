import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import BasicGrid from '../components/BasicGrid';

function Desenvolvimento() {
  const gridItems = [
    {
      size: 12,
      content: (
        <Box
          sx={{
            height: { xs: 'auto', md: '50rem' }, // Altura flexível em telas pequenas
            padding: 3,
            textAlign: 'left',
            borderRadius: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' }, // Coluna em telas pequenas, linha em grandes
            alignItems: 'flex-start',
          }}
        >
          {/* Box para a imagem */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', md: '40%' }, // 100% em telas menores
              paddingRight: { md: '20px' },
              marginBottom: { xs: 2, md: 0 }, // Espaço entre imagem e texto em telas pequenas
            }}
          >
            <picture>
              <source srcSet="images/notes.jpeg" type="image/jpeg" />
              <img
                src="images/bola.jpg"
                alt="Desenvolver Projeto de Software"
                style={{
                  width: '100%',
                  height: 'auto',
                  borderRadius: '8px',
                }}
              />
            </picture>
          </Box>


            {/* Conteúdo Principal */}
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '1200px', // Limita a largura do conteúdo
                }}
            >
                <Grid container spacing={4}>
                    {/* Título Principal */}
                    <Grid item xs={12}>
                        <Typography
                            variant="h3"
                            component="h1"
                            sx={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                color: 'black',
                                mb: 4, // Margem abaixo do título
                            }}
                        >
                            Desenvolvimento de Soluções Digitais
                        </Typography>
                    </Grid>

                    {/* Seção 1: Introdução */}
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                fontWeight: 'bold',
                                mb: 2, // Margem abaixo do subtítulo
                            }}
                        >
                            O Que Fazemos
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: 'justify',
                                textIndent: '30px',
                                lineHeight: 1.6,
                                mb: 3, // Margem abaixo do parágrafo
                            }}
                        >
                            Desenvolvemos soluções digitais eficientes, escaláveis e altamente funcionais, sempre com foco na excelência técnica e na entrega da melhor experiência possível ao usuário final. Utilizamos tecnologias modernas como React, Vue.js, Angular, Node.js, Python/Django, PHP/Laravel e Java/Spring Boot.
                        </Typography>
                    </Grid>

                    {/* Seção 2: Tecnologias */}
                    <Grid item xs={12} md={6}>
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                fontWeight: 'bold',
                                mb: 2,
                            }}
                        >
                            Tecnologias Utilizadas
                        </Typography>
                        <Box
                            component="ul"
                            sx={{
                                pl: 4, // Padding para a lista
                                mb: 3, // Margem abaixo da lista
                            }}
                        >
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Front-end:</strong> React, Vue.js, Angular
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Back-end:</strong> Node.js, Python/Django, PHP/Laravel, Java/Spring Boot
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Banco de Dados:</strong> SQL (MySQL, PostgreSQL) e NoSQL (MongoDB)
                                </Typography>
                            </li>
                        </Box>
                    </Grid>

                    {/* Seção 3: Benefícios */}
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                fontWeight: 'bold',
                                mb: 2,
                            }}
                        >
                            Benefícios do Nosso Trabalho
                        </Typography>
                        <Box
                            component="ul"
                            sx={{
                                pl: 4,
                                mb: 3,
                            }}
                        >
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Experiência do Usuário:</strong> Interfaces dinâmicas e responsivas.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Segurança:</strong> Sistemas robustos e protegidos.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1" component="span">
                                    <strong>Escalabilidade:</strong> Soluções que crescem com o seu negócio.
                                </Typography>
                            </li>
                        </Box>
                    </Grid>

                    {/* Seção 4: Conclusão */}
                    <Grid item xs={12}>
                        <Typography
                            variant="h5"
                            component="h2"
                            sx={{
                                fontWeight: 'bold',
                                mb: 2,
                            }}
                        >
                            Conclusão
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: 'justify',
                                textIndent: '30px',
                                lineHeight: 1.6,
                            }}
                        >
                            Em sites empresariais, e-commerces ou aplicações web personalizadas, nossa abordagem é sempre pautada na qualidade, segurança e otimização de desempenho. Nosso compromisso é com o sucesso do seu projeto e a entrega dos melhores resultados. Vamos transformar suas ideias em realidade digital!
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Box>
      )
    },
  ];

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pl: 2,
        pr: 2,
      }}
    >
      {/* Box da Imagem, separado do conteúdo principal */}
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'black',
          padding: 3,
        }}
      >
        <picture>
          <source srcSet="images/qualidade-de-software-1024x587.jpg" type="image/jpeg" />
          <img
            src="images/bola.jpg"
            alt="Desenvolver Projeto de Software"
            style={{
              maxWidth: '800px',
              width: '100%',
              height: 'auto',
            }}
          />
        </picture>
      </Box>

      {/* Conteúdo Principal */}
      <BasicGrid gridItems={gridItems} />
    </Box>
  );
}

export default Desenvolvimento;
