import React from 'react';
import { Box } from '@mui/material';

function FirstHomeSVG() {
  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '8px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
        <svg viewBox="0 0 640 427" xmlns="http://www.w3.org/2000/svg">
            <defs>
            <linearGradient id="bgGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#f8f9fa', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#e9ecef', stopOpacity: 1 }} />
            </linearGradient>
            <linearGradient id="boxGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" style={{ stopColor: '#4c6ef5', stopOpacity: 1 }} />
                <stop offset="100%" style={{ stopColor: '#748ffc', stopOpacity: 1 }} />
            </linearGradient>
            <marker id="arrowhead" markerWidth="10" markerHeight="7" refX="9" refY="3.5" orient="auto">
                <polygon points="0 0, 10 3.5, 0 7" fill="#495057" />
            </marker>
            </defs>

            <rect width="640" height="427" fill="url(#bgGradient)" />

            {/* Box for 'Desenvolvimento de Software' */}
            <g transform="translate(320, 40)">
                <rect x="-160" y="-20" width="320" height="50" rx="8" fill="url(#boxGradient)" opacity="0.9" />
                <text x="0" y="0" fontFamily="Arial" fontSize="16" fill="white" textAnchor="middle" alignmentBaseline="central" fontWeight="bold">
                    Desenvolvimento de Software
                </text>
            </g>

            {/* Other Boxes */}
            {[
            { x: 200, y: 120, text: ["Análise de", "Requisitos"], width: 180, fontSize: 14, icon: "📋" },
            { x: 440, y: 120, text: ["Planejamento e", "Arquitetura"], width: 180, fontSize: 14, icon: "🔧" },
            { x: 120, y: 220, text: ["Design UI/UX", "Responsivo"], width: 180, fontSize: 14, icon: "🎨" },
            { x: 320, y: 220, text: ["Desenvolvimento", "Full Stack"], width: 180, fontSize: 14, icon: "💻" },
            { x: 520, y: 220, text: ["Banco de Dados", "e Cloud"], width: 180, fontSize: 14, icon: "☁️" },
            { x: 120, y: 320, text: ["Testes e", "Qualidade"], width: 180, fontSize: 14, icon: "🔍" },
            { x: 320, y: 320, text: ["Segurança e", "Proteção"], width: 180, fontSize: 14, icon: "🔒" },
            { x: 520, y: 320, text: ["Deploy e", "Monitoramento"], width: 180, fontSize: 14, icon: "🚀" }
            ].map((item, index) => (
            <g key={index} transform={`translate(${item.x},${item.y})`}>
                <rect x="-90" y="-25" width={item.width} height="50" rx="8" fill="url(#boxGradient)" opacity="0.9" />
                {Array.isArray(item.text) ? (
                item.text.map((line, idx) => (
                    <text key={idx} x="0" y={idx * 20 - 5} fontFamily="Arial" fontSize={item.fontSize} fill="white" textAnchor="middle">
                    {line}
                    </text>
                ))
                ) : (
                <text x="0" y="5" fontFamily="Arial" fontSize={item.fontSize} fill="white" textAnchor="middle" fontWeight="bold">
                    {item.text}
                </text>
                )}
                <circle cx="-60" cy="0" r="15" fill="white" opacity="0.2" />
                <text x="-60" y="5" fontFamily="Arial" fontSize="14" fill="white" textAnchor="middle">
                {item.icon}
                </text>
            </g>
            ))}

            {/* Paths and Arrows */}
            <g stroke="#495057" strokeWidth="2" fill="none">
            <path d="M320,65 L320,85 L200,85 L200,95" markerEnd="url(#arrowhead)" />
            <path d="M320,65 L320,85 L440,85 L440,95" markerEnd="url(#arrowhead)" />
            <path d="M200,145 L200,185 L120,185 L120,195" markerEnd="url(#arrowhead)" />
            <path d="M200,145 L200,185 L320,185 L320,195" markerEnd="url(#arrowhead)" />
            <path d="M440,145 L440,185 L520,185 L520,195" markerEnd="url(#arrowhead)" />
            <path d="M120,245 L120,295" markerEnd="url(#arrowhead)" />
            <path d="M320,245 L320,295" markerEnd="url(#arrowhead)" />
            <path d="M520,245 L520,295" markerEnd="url(#arrowhead)" />
            </g>

            {/* Bottom Text */}
            <g transform="translate(320,390)">
            <text x="0" y="0" fontFamily="Arial" fontSize="14" fill="#495057" textAnchor="middle" fontStyle="italic">
                Desenvolvimento de Aplicações Responsivas e Escaláveis
            </text>
            </g>
        </svg>
    </Box>
  );
}

export default FirstHomeSVG;
