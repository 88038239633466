import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Para navegação

const Erro404 = () => {
    const navigate = useNavigate(); // Hook para navegação

    // Função para redirecionar à página inicial
    const handleGoHome = () => {
        navigate('/');
    };

    return (
        <Container maxWidth="sm" sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
                {/* Título */}
                <Typography variant="h1" component="h1" sx={{ fontWeight: 'bold', fontSize: '6rem', color: 'primary.main' }}>
                    404
                </Typography>

                {/* Subtítulo */}
                <Typography variant="h4" component="h2" sx={{ mt: 2, mb: 2 }}>
                    Oops! Página não encontrada.
                </Typography>

                {/* Mensagem */}
                <Typography variant="body1" sx={{ mb: 4 }}>
                    Parece que você tentou acessar uma página que não existe. Verifique o URL ou clique no botão abaixo para voltar à página inicial.
                </Typography>

                {/* Botão para voltar à página inicial */}
                <Button
                    variant="contained"
                    size="large"
                    onClick={handleGoHome}
                    sx={{ textTransform: 'none', fontWeight: 'bold' }}
                >
                    Voltar à Página Inicial
                </Button>
            </Box>
        </Container>
    );
};

export default Erro404;