import React, { useState, useEffect } from 'react';
import { Box, Fab, Zoom } from '@mui/material';
import { KeyboardArrowUp } from '@mui/icons-material';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    // Verifica a posição da rolagem para mostrar ou esconder a seta
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Função para mover a página para o topo
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Rolagem suave
        });
    };

    // Adiciona um listener para o evento de rolagem
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: '24px',
                right: '24px',
                zIndex: 1000, // Garante que a seta fique acima de outros elementos
            }}
        >
            {/* Animação de entrada/saída */}
            <Zoom in={isVisible}>
                <Fab
                    color="primary"
                    aria-label="Voltar ao topo"
                    onClick={scrollToTop}
                    sx={{
                        boxShadow: 3, // Sombra suave
                        '&:hover': {
                            backgroundColor: 'primary.dark', // Cor ao passar o mouse
                        },
                    }}
                >
                    <KeyboardArrowUp />
                </Fab>
            </Zoom>
        </Box>
    );
};

export default ScrollToTopButton;