import React from 'react';
import { Grid, Typography, Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const ThirdHomeComponent = () => {
  return (
    <Box sx={{ py: 8 }}>
       <Grid container spacing={4} alignItems="center" sx={{ width: '100%', padding: 4 }}>
        <Grid item xs={12} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Soluções
          </Typography>
        </Grid>

        {/* Produto 1 */}
        <Grid item xs={12} md={4}>
          <Box sx={{ border: '1px solid #ddd', borderRadius: 2, overflow: 'hidden' }}>
            <Link to="/responsive_apps" style={{ textDecoration: 'none' }}>
              <img
                src="images/application-develop-ment-services-desktop-combo.jpg"
                alt="Product 1"
                className="img-fluid product-image"
                style={{ width: '100%', height: 'auto' }}
              />
            </Link>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
              <span className="product-alert">Aplicações Responsivas</span>
              <Button  sx={{ padding: 0, minWidth: 'auto' }}>
                👍 
              </Button>
            </Box>
            <Box sx={{ display: 'flex', p: 1 }}>
              <Box>
                <Typography variant="h6">Para todos os dispositivos</Typography>
                <Typography variant="body2" color="text.secondary">
                  E em todos os lugares
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Produto 2 */}
        <Grid item xs={12} md={4}>
          <Box sx={{ border: '1px solid #ddd', borderRadius: 2, overflow: 'hidden' }}>
            <Link to="/seoadds" style={{ textDecoration: 'none' }}>
              <img
                src="images/seo.jpg"
                alt="Product 2"
                className="img-fluid product-image"
                style={{ width: '100%', height: 'auto' }}
              />
            </Link>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
              <span className="product-alert">Aumente a sua Visibilidade na Internet</span>
              <Button  sx={{ padding: 0, minWidth: 'auto' }}>
                👍 
              </Button>
            </Box>
            <Box sx={{ display: 'flex', p: 1 }}>
              <Box>
                <Typography variant="h6">Otimização Orgânica</Typography>
                <Typography variant="body2" color="text.secondary">
                  E Tráfego Pago
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        {/* Produto 3 */}
        <Grid item xs={12} md={4}>
          <Box sx={{ border: '1px solid #ddd', borderRadius: 2, overflow: 'hidden' }}>
            <Link to="/apiscloud" style={{ textDecoration: 'none' }}>
              <img
                src="images/api.jpg"
                alt="Product 3"
                className="img-fluid product-image"
                style={{ width: '100%', height: 'auto' }}
              />
            </Link>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
              <span className="product-alert">Mais Segurança e Eficiência</span>
              <Button  sx={{ padding: 0, minWidth: 'auto' }}>
                👍 
              </Button>
            </Box>
            <Box sx={{ display: 'flex', p: 1 }}>
              <Box>
                <Typography variant="h6">Integrações e Computação na Nuvem</Typography>
                <Typography variant="body2" color="text.secondary">
                  Sistemas distribuídos e microsserviços
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} textAlign="center">
          <Link to="/products" style={{ 
            display: 'none',
            textDecoration: 'none' }}>
            <Button variant="text" sx={{ mt: 3 }}>
              Saiba mais 
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ThirdHomeComponent;