// BasicGrid.js
import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function BasicGrid({ gridItems }) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {gridItems.map((item, index) => (
          <Grid item xs={item.size} key={index}>
            <Item elevation={0} variant="none">{item.content}</Item> {/* Remove sombra e borda */}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}