import React from 'react';
import { Box, Grid } from '@mui/material';
import FirstHomeComponent from '../components/FirstHomeComponent';
import MiddleHomeComponent from '../components/MiddleHomeComponent';
import ThirdHomeComponent from '../components/ThirdHomeComponent';

function Home() {
    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column', // Coloca os componentes em coluna
                alignItems: 'center',
                justifyContent: 'flex-start', // Alinha no topo
                width: '100%', // Ocupa 100% da largura da tela
            }}
        >
            {/* FirstHomeComponent com margem acima e abaixo */}
            <Grid item xs={12} sm={4}>
                <Box sx={{ width: '100%', my: 4 }}> {/* my: 4 = margem vertical de 32px */}
                    <FirstHomeComponent />
                </Box>
            </Grid>

            {/* MiddleHomeComponent com margem acima e abaixo */}
            <Grid item xs={12}
              sx ={{
                backgroundColor: '#f8f9fa',
                padding: '0' 
              }}            
            >
                <Box sx={{ width: '100%'}}> 
                    <MiddleHomeComponent />
                </Box>
            </Grid>

            {/* ThirdHomeComponent com margem acima e abaixo */}
            <Grid item xs={12} sm={4}>
                <Box sx={{ width: '100%', my: 4 }}> {/* my: 4 = margem vertical de 32px */}
                    <ThirdHomeComponent />
                </Box>
            </Grid>
        </Box>
    );
}

export default Home;