import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

const ResponsiveApps = () => {
    return (
        <Container maxWidth="md" sx={{ py: 4 }}>
            <Paper elevation={0}
                sx={{
                    p: 4,
                    backgroundColor: '#f8f9fa',
                }}
            >
                {/* Título Principal */}
                <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                    Aplicações Responsivas
                </Typography>

                {/* Subtítulo */}
                <Typography variant="h4" component="h2" gutterBottom sx={{ textAlign: 'center', color: 'primary.main' }}>
                     O que São e Qual Sua Importância?
                </Typography>

                {/* Introdução */}
                <Typography variant="body1" paragraph>
                    Com o avanço da tecnologia e a diversidade de dispositivos disponíveis, como smartphones, tablets e desktops, tornou-se essencial que as aplicações web se adaptem a diferentes tamanhos de tela. Essa necessidade deu origem ao conceito de aplicações responsivas, que são sistemas projetados para oferecer uma experiência de uso consistente e otimizada independentemente do dispositivo utilizado.
                </Typography>

                {/* O Que São Aplicações Responsivas? */}
                <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
                    O Que São Aplicações Responsivas?
                </Typography>
                <Typography variant="body1" paragraph>
                    Uma aplicação responsiva é um site ou sistema que ajusta automaticamente sua interface e disposição dos elementos para se adaptar ao tamanho da tela do usuário. Isso significa que um mesmo código pode ser executado em diferentes dispositivos sem comprometer a usabilidade, eliminando a necessidade de criar versões separadas para cada tipo de tela.
                </Typography>
                <Typography variant="body1" paragraph>
                    Para alcançar essa flexibilidade, as aplicações responsivas utilizam técnicas como:
                </Typography>
                <Box component="ul" sx={{ pl: 4, mb: 2 }}>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Layouts flexíveis:</strong> Uso de grids e unidades relativas (% ou em em vez de valores fixos em px).
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Media Queries:</strong> Permitem definir estilos específicos para diferentes tamanhos de tela.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Imagens adaptáveis:</strong> Ajustam seu tamanho conforme o espaço disponível.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Elementos interativos otimizados:</strong> Botões e menus que se adaptam para telas menores.
                        </Typography>
                    </li>
                </Box>

                {/* Importância das Aplicações Responsivas */}
                <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
                    Importância das Aplicações Responsivas
                </Typography>
                <Typography variant="body1" paragraph>
                    A responsividade não é apenas uma tendência, mas uma necessidade para o sucesso de qualquer aplicação moderna. Algumas das principais vantagens incluem:
                </Typography>
                <Box component="ul" sx={{ pl: 4, mb: 2 }}>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Melhor Experiência do Usuário (UX):</strong> Uma interface bem ajustada evita zoom e rolagens desnecessárias, tornando a navegação mais intuitiva.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>SEO e Melhor Ranqueamento:</strong> O Google prioriza sites responsivos nos resultados de busca, melhorando a visibilidade online.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Redução de Custos e Manutenção:</strong> Com um único código para diferentes dispositivos, evita-se a necessidade de desenvolver versões separadas para mobile e desktop.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Maior Alcance e Acessibilidade:</strong> Garante que qualquer usuário, independentemente do dispositivo, possa acessar e utilizar a aplicação sem dificuldades.
                        </Typography>
                    </li>
                </Box>

                {/* Tecnologias Utilizadas */}
                <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
                    Tecnologias Utilizadas
                </Typography>
                <Typography variant="body1" paragraph>
                    Para desenvolver aplicações responsivas, algumas tecnologias e frameworks são amplamente utilizados:
                </Typography>
                <Box component="ul" sx={{ pl: 4, mb: 2 }}>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>CSS Flexbox e Grid:</strong> Para criação de layouts flexíveis.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Media Queries:</strong> Para definir regras de estilo conforme o tamanho da tela.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>Bootstrap e Material-UI:</strong> Frameworks que já oferecem componentes responsivos.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1" component="span">
                            <strong>React Native e Flutter:</strong> Para desenvolvimento de aplicações móveis com design responsivo.
                        </Typography>
                    </li>
                </Box>

                {/* Conclusão */}
                <Typography variant="h5" component="h3" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
                    Conclusão
                </Typography>
                <Typography variant="body1" paragraph>
                    Aplicações responsivas são fundamentais para garantir acessibilidade, usabilidade e alcance a um público maior. Com a crescente variedade de dispositivos no mercado, investir em responsividade deixou de ser um diferencial e se tornou um requisito básico para o sucesso digital.
                </Typography>
                <Typography variant="body1" paragraph>
                    Se você deseja desenvolver um site ou sistema moderno, responsivo e otimizado, é essencial considerar essas técnicas e boas práticas desde o início do projeto.
                </Typography>
            </Paper>
        </Container>
    );
};

export default ResponsiveApps;