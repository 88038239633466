import React from 'react';
import { Grid, Typography, Box, Container } from '@mui/material';
import FirstHomeSVG from './FirstHomeSVG'; // Supondo que você tenha esse componente

const FirstHomeComponent = () => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={4} alignItems="center" sx={{ width: '100%', padding: 4 }}>
        {/* Título ocupando linha completa */}
        <Grid item xs={12}>
          <Typography variant="h4" fontWeight="bold" gutterBottom align="center">
            Bem-vindos a<span style={{ color: '#336699' }}> OS</span> Projetos
          </Typography>
        </Grid>

        {/* Primeira coluna */}
        <Grid item xs={12} md={3}>
          <Typography
            sx={{
              textIndent: '30px',
              fontSize: { xs: '16px', md: '18px' },
              textAlign: 'justify',
              marginBottom: '10px',
            }}
          >
            Apps e Sites Responsivos, compatíveis com todos os tipos de dispositivos. Inteligência Artificial, Big Data, Computação na Nuvem e Segurança na Rede. Web Semântica, Otimização Orgânica e Tráfego Pago. T.I.C - Tecnologias da Informação e da Comunicação e os melhores padrões de desenvolvimento a alguns cliques de distância. Esse é o nosso trabalho e pode ser o seu sucesso!
          </Typography>
        </Grid>

        {/* Segunda coluna */}
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              width: '100%',
              borderRadius: '8px',
              overflow: 'hidden',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <FirstHomeSVG />
          </Box>
        </Grid>

        {/* Terceira coluna */}
        <Grid item xs={12} md={4}>
          <Typography
            sx={{
              textIndent: '30px',
              fontSize: { xs: '16px', md: '18px' },
              textAlign: 'justify',
              marginBottom: '10px',
            }}
          >
            Há mais de 12 anos no mercado web, podemos dizer que vimos muitas coisas surpeendentes acontecerem, inúmeros casos de sucesso e poucas, pessoas ou empresas, aproveitando todo o potencial da rede. Sabemos que não é falta de tentativas, muito menos de boa vontade, o que leva a resultados insatisfatórios. Tampouco, embora haja muita gente com sorte grande, é o acaso que leva ao sucesso.
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FirstHomeComponent;
