import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';

function Solucoes() {
    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 3,
                backgroundColor: '#f9f9f9', // Fundo claro para melhor contraste
            }}
        >
            {/* Container Principal */}
            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    maxWidth: '1200px', // Limita a largura do conteúdo
                    padding: 4
                }}
            >
                {/* Título Principal */}
                <Typography
                    variant="h3"
                    component="h1"
                    sx={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'primary.main',
                        mb: 4, // Margem abaixo do título
                    }}
                >
                    Nossas Soluções
                </Typography>

                {/* Introdução */}
                <Typography
                    variant="body1"
                    sx={{
                        textAlign: 'justify',
                        lineHeight: 1.6,
                        mb: 4, // Margem abaixo do texto
                    }}
                >
                    Se você tem uma ideia e força de vontade suficiente para colocá-la em prática, nós podemos desenvolver um projeto de sucesso e transformar seus sonhos em realidade! Sejam eles pequenos ou grandes, a internet oferece a chance de realizá-los. E, com nossa expertise, nós podemos tornar o seu caminho mais fácil e te ajudar a alcançar seus objetivos mais rápido. Abaixo algumas das opções que oferecemos:
                </Typography>

                {/* Grid para Organizar as Soluções */}
                <Grid container spacing={4}>
                    {/* Coluna de Texto */}
                    <Grid item xs={12} md={8}>
                        {/* Sites Institucionais */}
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Sites Institucionais
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Personalizados para empresas, organizações e profissionais que buscam estabelecer uma presença online sólida e profissional, projetados para transmitir a identidade da marca, fornecer informações claras e facilitar a navegação.
                            </Typography>
                        </Box>

                        {/* E-commerces */}
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                E-commerces
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Plataformas de comércio eletrônico completas, para negociar produtos ou serviços de maneira eficiente e segura. Trabalhamos com soluções como Shopify, WooCommerce, Magento, além de plataformas personalizadas, sempre com ênfase na usabilidade e na integração com sistemas de pagamento, logística e gestão de estoque.
                            </Typography>
                        </Box>

                        {/* Web Apps e Aplicações Personalizadas */}
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Web Apps e Aplicações Personalizadas
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Aplicações web que atendem a necessidades específicas, como plataformas de gerenciamento, sistemas de automação, dashboards interativos e integrações com outras ferramentas corporativas.
                            </Typography>
                        </Box>

                        {/* Landing Pages e Páginas de Vendas */}
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Landing Pages e Páginas de Vendas
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Otimizadas para conversão, com foco em atrair e engajar visitantes para ações específicas, como inscrições, downloads ou compras. Essas páginas são criadas com design intuitivo e estratégias de SEO e copywriting.
                            </Typography>
                        </Box>

                        {/* Blogs e Portais de Conteúdo */}
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Blogs e Portais de Conteúdo
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Oferecemos aos nossos clientes uma plataforma de fácil gestão de posts, categorias e interações com o público. Utilizamos WordPress, Ghost ou plataformas customizadas.
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Coluna da Imagem */}
                    <Grid item xs={12} md={4}>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                            }}
                        >
                          <picture>
                            <source srcSet="images/notes.jpeg" type="image/jpeg" />
                            <img
                              src="images/bola.jpg"
                              alt="Desenvolver Projeto de Software"
                              style={{
                                width: '100%',
                                height: 'auto',
                                borderRadius: '8px',
                              }}
                            />
                          </picture>
                        </Box>
                    </Grid>
                </Grid>

                {/* Segunda Parte das Soluções */}
                <Grid container spacing={4} sx={{ mt: 2 }}>
                    {/* Sistemas de Gestão de Conteúdo (CMS) */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Sistemas de Gestão de Conteúdo (CMS)
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                CMS personalizados que permitem aos nossos clientes gerenciar seus sites de maneira fácil e intuitiva, sem necessidade de conhecimentos técnicos.
                            </Typography>
                        </Box>
                    </Grid>

                    {/* SEO (Otimização para Mecanismos de Busca) */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                SEO (Otimização para Mecanismos de Busca)
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Para melhorar a visibilidade e o ranking nos motores de busca, implementando estratégias de otimização.
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Desenvolvimento Mobile (Responsivo e Apps) */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Desenvolvimento Mobile (Responsivo e Apps)
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Garantimos que todos os sites que criamos sejam totalmente responsivos e adaptáveis a qualquer tamanho de tela.
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Integrações e APIs */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Integrações e APIs
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Integrações personalizadas entre diferentes sistemas, plataformas de pagamento, ERPs, CRMs e outras soluções corporativas.
                            </Typography>
                        </Box>
                    </Grid>

                    {/* Hospedagem e Manutenção Web */}
                    <Grid item xs={12} md={6}>
                        <Box sx={{ mb: 4 }}>
                            <Typography variant="h5" component="h2" sx={{ fontWeight: 'bold', mb: 2 }}>
                                Hospedagem e Manutenção Web
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify', lineHeight: 1.6 }}>
                                Na nuvem, ou de baixo custo, para garantir que os sites de nossos clientes tenham a performance e a segurança adequadas.
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}

export default Solucoes;