import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Alert } from '@mui/material';

function Contato() {
  // Estados para os campos do formulário
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [mensagem, setMensagem] = useState('');

  // Estados para feedback (sucesso/erro)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  // Função para enviar o formulário
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validação básica
    if (!nome || !email || !mensagem) {
      setSnackbarMessage('Por favor, preencha todos os campos obrigatórios.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
    }

    // Dados do formulário
    const formData = {
      nome,
      email,
      whatsapp,
      assunto: 'Contato via OSProjetos.com.br', // Assunto padrão
      mensagem,
    };

    try {
      // Envia os dados para o backend PHP
      const response = await fetch('https://osprojetos.com.br/backend/send-email.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const result = await response.json();

      if (result.success) {
        setSnackbarMessage('E-mail enviado com sucesso! Obrigado!');
        setSnackbarSeverity('success');
      } else {
        setSnackbarMessage(result.message || 'Erro ao enviar o e-mail.');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      setSnackbarMessage('Erro ao conectar com o servidor.');
      setSnackbarSeverity('error');
    } finally {
      setOpenSnackbar(true);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 3,
        minHeight: '100vh',
        margin: '0 auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'center',
          textAlign: 'left',
          padding: 3,
          maxWidth: '600px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            textIndent: '30px',
          }}
        >
          Agradecemos a gentileza de nos contactar. Por favor, preencha os dados abaixo e responderemos o mais rápido possível! Até logo!
        </Typography>
      </Box>

      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          justifyContent: 'center',
          padding: 3,
          maxWidth: '600px',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        {/* Título do formulário */}
        <Typography variant="h4" gutterBottom>
          Contato
        </Typography>

        {/* Campo Nome */}
        <TextField
          fullWidth
          label="Nome"
          variant="outlined"
          margin="normal"
          required
          value={nome}
          onChange={(e) => setNome(e.target.value)}
        />

        {/* Campo E-mail */}
        <TextField
          fullWidth
          label="E-mail"
          type="email"
          variant="outlined"
          margin="normal"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        {/* Campo WhatsApp */}
        <TextField
          fullWidth
          label="WhatsApp"
          type="phone"
          variant="outlined"
          margin="normal"
          value={whatsapp}
          onChange={(e) => setWhatsapp(e.target.value)}
        />

        {/* Campo Mensagem */}
        <TextField
          fullWidth
          label="Mensagem"
          variant="outlined"
          margin="normal"
          required
          multiline
          rows={4}
          value={mensagem}
          onChange={(e) => setMensagem(e.target.value)}
        />

        {/* Botão Enviar */}
        <Button
          type="submit"
          variant="contained"
          sx={{
            marginTop: 2,
            width: '100%',
            backgroundColor: 'black',
            color: 'white',
            '&:hover': {
              backgroundColor: 'gray',
            },
          }}
        >
          Enviar
        </Button>
      </Box>

      {/* Lista com textos alinhados à esquerda e sem decoração */}
      <ul style={{
        listStyle: 'none',
        paddingLeft: 0,
        textAlign: 'left',
        marginTop: '20px',
      }}>
        <li>* Campos obrigatórios</li>
        <li>O WhatsApp é opcional, mas pode agilizar muito nossa comunicação!</li>
      </ul>

      {/* Snackbar para feedback */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Contato;