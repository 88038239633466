import './App.css';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import CarouselComponent from './components/Carousel';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import Desenvolvimento from './pages/Desenvolvimento';
import Solucoes from './pages/Solucoes';
import Contato from './pages/Contato';
import Footer from './components/Footer';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '@fontsource/inter/100.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import '@fontsource/inter/900.css';
import Login from './pages/Login';
import ResponsiveApps from './pages/AppsResponsivos';
import SeoAdds from './pages/SeoAdds';
import ApisCloud from './pages/ApisCloud';
import Erro404 from './pages/Erro404';
import ScrollToTopButton from './components/ScrollToTopButton';

const theme = createTheme({
  palette: {
    primary: {
      main: '#007bff', // ou sua cor primária
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
    fontWeightLight: 300, // Padrão para fontes finas
    fontWeightRegular: 400, // Padrão para fontes normais
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h6: {
      fontSize: '1.5rem', // Tamanho do logo
      fontWeight: 300,
    },
    button: {
      fontWeight: 300, // Fonte mais fina para botões
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {
        root: {
          minHeight: 90, // Altura da toolbar
          padding: '0 24px', // Padding horizontal
          fontWeight: 300, // Fonte mais fina específica para botões
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1.1rem', // Tamanho da fonte dos botões
          textTransform: 'none', // Mantém o texto em caixa normal
          fontWeight: 300, // Fonte mais fina específica para botões
          padding: '10px 16px', // Padding interno dos botões
        },
      },
    },
  },
});
function AppContent() {
  const location = useLocation();
  
  const items = [
    {
      title: 'Recriando a Relidade',
      description: 'Novidades, em breve!',
      image: '/images/banner0.jpg'
    },
    {
      title: 'WEB 2.0',
      description: 'Padrão de Projetos!',
      image: '/images/web2.0.jpg'
    },
    {
      title: 'Recriando a Relidade',
      description: 'Novidades, em breve!',
      image: '/images/banner3.jpg'
    },
  ];

  return (
    <div className="App">
      <ResponsiveAppBar />
      
      {/* Renderiza o carrossel somente se a rota for "/" */}
      {location.pathname === '/' && <CarouselComponent items={items} />}
      
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/desenvolvimento" element={<Desenvolvimento />} />
        <Route path="/solucoes" element={<Solucoes />} />
        <Route path="/contato" element={<Contato />} />
        <Route path="/login" element={<Login />} />
        <Route path="/responsive_apps" element={<ResponsiveApps />} />
        <Route path="/seoadds" element={<SeoAdds />} />
        <Route path="/apiscloud" element={<ApisCloud />} />
        <Route path="*" element={<Erro404 />} />
      </Routes>
      <ScrollToTopButton />
      <Footer/>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}

export default App;